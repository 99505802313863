<template>
    <div class="topic-process-detail">
        <div class="topic-complete-type">
            <div class="bg-block">
                <div class="bg-item" :style="{backgroundColor: activeColor}"></div>
                <div class="bg-txt">
                    {{activeTxt}}
                </div>
            </div>
            <div class="bg-block">
                <div class="bg-item bg" :style="{backgroundColor: bgColor}"></div>
                <div class="bg-txt">
                    {{bgTxt}}
                </div>
            </div>
        </div>
        <div class="topic-process-conent">
            <el-scrollbar style="height: 100%">
                <el-timeline>
                    <el-timeline-item v-for="(item, key) in timeline" :key="key"
                                      :timestamp="item.label" placement="top"
                                      :icon="item.icon"
                                      :color="item.color"
                                      :class="{'el-timeline-done': item.done && isLineColor}"
                    >
                        <div class="time-line-main" @click="jumpTo(item)">
                            <div class="item-process-item" v-for="(item, index) in item.children"
                                 :style="{backgroundColor : item.status ? activeColor: bgColor }"
                                 :class="{'bg': !item.status}"
                            >{{index + 1}}</div>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common";
import _ from "underscore";
export default {
    name: "topicProceeDetail",
    props: {
        list: Array,
        bgColor: {
            type: String,
            default: '#FFFFFF'
        },
        bgTxt: {
            type: String,
            default: '未完成'
        },
        activeTxt: {
            type: String,
            default: '已完成'
        },
        activeColor: {
            type: String,
            default: '#E0E4FE'
        },
        falseValue: String,
        stuData: {
            type: Array,
            default: []
        },
        topicList: {
            type: Array,
            default: []
        },
        listenFlag: {
            type: Array,
            default: []
        },
        statusColor: {
            type: String,
            default: '#67C23A'
        },
        isLineColor: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            timeline: [],
        }
    },
    mounted() {
        this.formatTimeline();
    },
    watch: {
        topicList(val) {
            this.formatTimeline();
        },
        listenFlag(val) {
            this.formatTimeline();
        }
    },
    methods: {
        jumpTo(val) {
            this.$emit('jump', val.name);
        },
        formatTimeline() {
            let timeline_tmp = [];
            this.topicList.map((item) => {
                item.label = common.formatTopicLabel(item.name,false);
                let isCompleteCount = 0;
                item.children.map((val) => {
                    let status = _.find(this.stuData, (answer) => {
                        return answer.school_theory_topic_id === val.id;
                    });
                    if (status.student_answer !== this.falseValue) {
                        val.status = true;
                        isCompleteCount++;
                        if (!item.icon && !status.hasOwnProperty('is_true')) {
                            item.icon = 'el-icon-video-play';
                            item.color = this.statusColor;
                        }
                    } else {
                        val.status = false;
                        isCompleteCount--;
                        if (isCompleteCount < 0) {
                            isCompleteCount = 0;
                        }
                    }
                    if (status.hasOwnProperty('is_true') &&  Number(status.is_true) === 0) {
                        val.status = false;
                    }
                });
                if (item.children.length === isCompleteCount && this.isLineColor) {
                    item.done = true;
                    item.icon = 'el-icon-check';
                    item.color = this.statusColor;
                }
                if (isCompleteCount === 0) {
                    item.done = false;
                    item.icon = "";
                    item.color = "";
                }
                timeline_tmp.push(item);
            });
            this.timeline = [].concat(timeline_tmp);
        }
    }

}
</script>

<style scoped lang="scss">
.topic-process-detail {
    height: 100%;
    & * {
        box-sizing: border-box;
    }
    ul, li {
        padding: 0px;
        margin: 0px;
    }
    .topic-complete-type {
        height: 68px;
        width: 100%;
        background-color: #FFF;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 30px;
            &:last-child {
                margin-right: 0px;
            }
            .bg-item {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                margin-right: 10px;
                &.bg {
                    border: 1px solid #DDDDDD;
                }
            }
        }
    }
    .topic-process-conent {
        height: calc(100% - 108px);
        &::v-deep {
            .el-timeline-item {
                margin-left: 8px;
            }
            .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .time-line-main {
            cursor: pointer;
            width: 100%;
            display: inline-grid;
            background-color: #FFF;
            border-radius: 8px;
            padding: 20px;
            min-height: 100px;
            grid-template-columns: repeat(6, minmax(10px,1fr));
            grid-column-gap: 10px;
            grid-row-gap: 20px;
            .item-process-item {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                width: 40px;
                height: 40px;
                &.bg {
                    border: 1px solid #DDDDDD;
                }
            }
        }
        &::v-deep .el-timeline {
            .el-timeline-item {
                &.el-timeline-done {
                    .el-timeline-item__tail {
                        border-left: 2px solid #67C23A;
                    }
                }
            }
        }
    }

}

</style>