<template>
    <div class="answer-wrap">
        <div class="header">
            <div class="header-left">
                {{process_index}}/{{process_page_total}}训练
                <btn-process :page="process_index" :total="process_page_total" @change="toChange"></btn-process>
            </div>
            <div class="header-right">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt=""/>
                    </div>
                    <div class="user-name">{{username}}</div>
                </div>
                <div class="exit-btn" @click="studentLogout">
                    <i class="iconfont">&#xe698;</i>
                    <!--                    <span>退出</span>-->
                </div>
            </div>
        </div>
        <div class="answer-main">
            <div class="main-item answer-left">
                <el-scrollbar class="exerciseDetail-main" :native="false">
                    <AnswerDetail :paperDetail="paperDetail"></AnswerDetail>
                </el-scrollbar>
            </div>
            <div class="main-item answer-right">
                <div class="back-btn">
                    <el-button plain @click="toBack">返回</el-button>
                </div>
                <!--TODO 答题卡组件放这里-->
                <div class="process-bar">
                    <el-scrollbar style="height: 100%">
                        <topic-procee-detail
                            bg-color="#FBE8E1"
                            active-color="#E7F6EF"
                            bg-txt="错误"
                            active-txt="正确"
                            :is-line-color="false"
                            :stu-data="stuData"
                            :topic-list="topicTimeLine"
                            :listen-flag="listenFlag"
                        ></topic-procee-detail>
                    </el-scrollbar>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import AnswerDetail from "@/components/AnswerDetail";
    import {studentAnswerDetail} from '@/utils/apis'
    import TopicProceeDetail from "@/components/topicComponents/topicProceeDetail";
    import BtnProcess from "@/components/topicComponents/btnProcess";

    export default {
        name: "StudentAnswerDetail",
        components: {
            BtnProcess,
            TopicProceeDetail,
            AnswerDetail
        },
        data() {
            return {
                username: '',
                userIcon: '',
                paperDetail: {},
                process_index: this.$route.query.process_index ? Number(this.$route.query.process_index) : 0,
                process_page_total: this.$route.query.process_page_total ? Number(this.$route.query.process_page_total) : 0,
                train_id: this.$route.query.train_id ? Number(this.$route.query.train_id) : null,
                times_id: this.$route.query.times_id ? Number(this.$route.query.times_id) : null,
                stuData: [],
                topicTimeLine: [],
                listenFlag: [],
                keyBox: 0
            }
        },
        mounted() {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            if (this.username === null) {
                this.$message({
                    type: 'warning',
                    message: '请先登录',
                    onClose: () => {
                        this.studentLogout();
                    }
                });
            }
            if (this.train_id) {
                this.getAnswerDetail()
            }
        },
        methods: {
            toChange(val) {
                let query = this.$route.query;
                let times_id = query.times_id;
                let train_id = query.train_id
                let total = Number(this.process_page_total);
                if (val && this.process_index < total) {
                    this.process_index++;
                } else if (!val && this.process_index > 1) {
                    this.process_index--;
                } else {
                    return;
                }
                this.$router.push({
                    path: '/student/train/studentAnswerDetail',
                    query: {
                        process_index: this.process_index,
                        times_id: times_id,
                        train_id: train_id,
                        process_page_total: this.process_page_total
                    }
                });
                window.location.reload();
            },
            //退出
            studentLogout() {
                this.$http.axiosGet(this.$api.out, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.msg,
                            onClose: () => {
                                this.$lockr.flush();
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/') {
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 1000,
                            message: res.msg,
                            onClose: () => {
                                this.$lockr.flush();
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/') {
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    }
                })
            },
            toBack() {
                this.$router.push('/student/trainmanage/studenttrainlist')
            },
            getAnswerDetail() {
                let params = {
                    page: this.process_index,
                    train_id: this.train_id,
                    times_id: this.times_id
                }
                studentAnswerDetail(params).then(res=>{
                    if(res.code == 200){
                        this.paperDetail = res.data;
                        this.timeLineData(res.data);
                        for(let i in this.paperDetail){
                            this.paperDetail[i].list.forEach(item=>{
                                item.options = JSON.parse(item.options)
                                item.answer = JSON.parse(item.answer)
                                item.stu_answer = JSON.parse(item.stu_answer)
                                this.$set(item, 'isAnalysisShow', false)
                            })
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            timeLineData(data) {
                for(let key in data) {
                    if (data[key].total) {
                        let timeline = { name: key, children: [] }
                        data[key].list.map((item) => {
                            let stu_answer_item = {
                                school_theory_topic_id: item.school_theory_topic_id,
                                student_answer: '[]',
                                is_true: item.is_true
                            };
                            let stu_answer_flag = {id: item.school_theory_topic_id, status: false};
                            if (JSON.parse(item.stu_answer).length) {
                                stu_answer_item.student_answer = item.stu_answer;
                                stu_answer_flag.status = true;
                            }
                            this.stuData.push(stu_answer_item);
                            timeline.children.push(stu_answer_flag);
                        })
                        this.topicTimeLine.push(timeline);
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .stu-process-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 37px;
        .process-btn {
            width: 120px;
            height: 40px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #1122D8;
            .iconfont {
                font-size: 20px;
            }
            color: #1122D8;
            cursor: pointer;
            &.btn-up {
                margin-right: 9px;
                span {
                    margin-left: 8px;
                }
            }
            &.btn-down {
                i.iconfont {
                    transform: rotate(180deg);
                }
                span {
                    margin-right: 8px;
                }
            }
            &.disabled {
                border: 1px solid #E5E5E5;
                color: #E5E5E5;
                cursor: not-allowed;
            }
        }
        i.iconfont {
            font-size: 15px;
        }
    }
    .answer-wrap {
        background: #F2F3FA;
        height: 100vh;
        min-width: 1200px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                color: #1122D8;
                font-size: 18px;
                margin-left: 40px;
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 20px;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .answer-main {
            margin: 20px;
            height: calc(100% - 100px);
            display: flex;

            .main-item {
                height: 100%;
            }

            .answer-left {
                flex: 1;
                margin-right: 20px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px 0px rgba(17, 34, 216, 0.1);
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                .exerciseDetail-main {
                    height: 1%;
                    flex: 1;

                    ::v-deep > .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                }
            }

            .answer-right {
                width: 370px;

                .back-btn {
                    height: 40px;

                    .el-button {
                        width: 100%;
                        background: #1122D8;
                        border: 1px solid #E5E5E5;
                        border-radius: 4px;
                        color: #fff;
                    }
                }
            }
        }
    }
    .process-bar {
        width: 100%;
        height: calc(100% - 40px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
</style>