<template>
    <div class="stu-process-btns">
        <div class="btn-up process-btn" :class="{'disabled': pageOne}" @click="toChange(0)"><i class="iconfont">&#xe649;</i><span>上一组</span></div>
        <div class="process-btn btn-down" :class="{'disabled': pageLast}" @click="toChange(1)"><span>下一组</span><i class="iconfont">&#xe649;</i></div>
    </div>
</template>

<script>
export default {
    name: "btnProcess",
    props: {
        page: [String, Number],
        total: [String, Number]
    },
    computed: {
        pageOne() {
            return Number(this.page) === 1;
        },
        pageLast() {
            return Number(this.page) === this.total;
        }
    },
    methods: {
        toChange(val) {
            this.$emit('change', val);
        }
    }
}
</script>

<style scoped lang="scss">
.stu-process-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 37px;
    .process-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1122D8;
        .iconfont {
            font-size: 20px;
        }
        color: #1122D8;
        cursor: pointer;
        &.btn-up {
            margin-right: 9px;
            span {
                margin-left: 8px;
            }
        }
        &.btn-down {
            i.iconfont {
                transform: rotate(180deg);
            }
            span {
                margin-right: 8px;
            }
        }
        &.disabled {
            border: 1px solid #E5E5E5;
            color: #E5E5E5;
            cursor: not-allowed;
        }
    }
    i.iconfont {
        font-size: 15px;
    }
}

</style>